// TODO: Lazy load large routes

// General items
import Feedback from "@/pages/Feedback";
import Home from "@/pages/Home";

// Admin
import AdminPanel from "@/pages/Admin/AdminPanel";
import CreateNewsArticle from "@/pages/Admin/NewsArticles/CreateNewsArticle";
import EditNewsArticle from "@/pages/Admin/NewsArticles/EditNewsArticle";
import ManageNewsArticles from "@/pages/Admin/NewsArticles/ManageNewsArticles";
import CreateTopical from "@/pages/Admin/TopicalNews/CreateTopical";
import EditTopical from "@/pages/Admin/TopicalNews/EditTopical";
import ManageTopical from "@/pages/Admin/TopicalNews/ManageTopical";

// Settings
import GroupSettings from "@/pages/Settings/GroupSettings";

// Auth / user
import Login from "@/pages/Login";
import ResetPasswordSendMail from "@/pages/PasswordReset/ResetPasswordSendMail";
import ResetPasswordSent from "@/pages/PasswordReset/ResetPasswordSent";
import ResetPasswordSetNew from "@/pages/PasswordReset/ResetPasswordSetNew";
import Profile from "@/pages/Profile";
import Register from "@/pages/Register";
import ChangePassword from "@/pages/Settings/ChangePassword";
import Users from "@/pages/Users";

// System
import NotFound from "@/pages/NotFound";

// Pages
// Ueber uns
import Abteilung from "@/pages/Ueber-uns/Abteilung";
import Ausbildung from "@/pages/Ueber-uns/Ausbildung";
import CeviNews from "@/pages/Ueber-uns/Cevi-News";
import CeviTurnen from "@/pages/Ueber-uns/Cevi-Turnen";
import Dokumente from "@/pages/Ueber-uns/Dokumente";
import Organisation from "@/pages/Ueber-uns/Organisation";
import Stuetzpunkte from "@/pages/Ueber-uns/Stuetzpunkte";
import Taetigkeit from "@/pages/Ueber-uns/Taetigkeit";
import UeberUns from "@/pages/Ueber-uns/Ueber-uns";
// Anlaesse
import Anlaesse from "@/pages/Anlaesse/Anlaesse";
import CeviFlohmi from "@/pages/Anlaesse/Cevi-Flohmi";
import Chilbi from "@/pages/Anlaesse/Chilbi";
import CleanUpDay from "@/pages/Anlaesse/Clean-Up-Day";
import FlossRallye from "@/pages/Anlaesse/Floss-Rallye";
import PiHeKu from "@/pages/Anlaesse/PiHeKu";
import Snowday from "@/pages/Anlaesse/Snowday";
import SoLa from "@/pages/Anlaesse/SoLa";
import WaWei from "@/pages/Anlaesse/WaWei";
import ZoBack from "@/pages/Anlaesse/ZoBack";
import ZoBackOrder from "@/pages/Anlaesse/ZoBackOrder";
// Stufen
import EditGroupBio from "@/pages/Stufen/EditGroupBio";
import EditNewsFeed from "@/pages/Stufen/EditNewsFeed";
import GroupFeed from "@/pages/Stufen/GroupFeed";
import GroupPage from "@/pages/Stufen/GroupPage";
import SendNewsFeed from "@/pages/Stufen/SendNewsFeed.vue";
import Stufen from "@/pages/Stufen/Stufen";
// Material
import Fallbalken from "@/pages/Material/Fallbalken";
import FallbalkenReservation from "@/pages/Material/FallbalkenReservation";
import HoernliHaus from "@/pages/Material/HoernliHaus";
import Mat from "@/pages/Material/Mat";
import Material from "@/pages/Material/Material";
import MatOrder from "@/pages/Material/MatOrder";
import Pizzamobil from "@/pages/Material/Pizzamobil";
import PizzamobilReservation from "@/pages/Material/PizzamobilReservation";
// Laedeli
import Laedeli from "@/pages/Laedeli/Laedeli";
// Links
import Links from "@/pages/Links/Links";
// Laedeli
import Kontakt from "@/pages/Kontakt/Kontakt";
// Verband
import Verband from "@/pages/Verband/Verband";

// Helfer tools
import HelpersAdminView from "@/pages/Helfer/HelpersAdminView";
import HelpersOverview from "@/pages/Helfer/HelpersOverview";
import RequestHelpers from "@/pages/Helfer/RequestHelpers";

import store from "@/store";

export const routes = [
	// General items
	{ path: "/", name: "home", component: Home },
	{ path: "/users", component: Users },
	{ path: "/register", component: Register },
	{ path: "/login", name: "login", component: Login },
	{ path: "/settings/groups", component: GroupSettings },
	{ path: "/feedback", component: Feedback },
	{
		path: "/admin",
		component: AdminPanel,
		children: [
			{ path: "articles/manage", component: ManageNewsArticles },
			{ path: "articles/:id/edit", component: EditNewsArticle },
			{ path: "articles/create", component: CreateNewsArticle },
			{ path: "aktuelles/manage", component: ManageTopical },
			{ path: "aktuelles/create", component: CreateTopical },
			{ path: "aktuelles/:id/edit", component: EditTopical },
		],
	},
	{ path: "/profile", component: Profile },
	{ path: "/change-password", component: ChangePassword },
	{ path: "/reset-password", component: ResetPasswordSendMail },
	{ path: "/reset-password-sent", component: ResetPasswordSent },
	{
		path: "/reset-password/:userId/:resetToken",
		component: ResetPasswordSetNew,
	},

	// Pages
	// Ueber uns
	{
		path: "/ueber-uns",
		component: UeberUns,
		children: [
			{ path: "taetigkeit", component: Taetigkeit },
			{ path: "abteilung", component: Abteilung },
			{ path: "organisation", component: Organisation },
			{ path: "dokumente", component: Dokumente },
			{ path: "stuetzpunkte", component: Stuetzpunkte },
			{ path: "ausbildung", component: Ausbildung },
			{ path: "cevi-news", component: CeviNews },
			{ path: "cevi-turnen", component: CeviTurnen },
		],
	},

	// Anlaesse
	{ path: "/anlaesse", component: Anlaesse },
	{ path: "/anlaesse/cevi-flohmi", component: CeviFlohmi },
	{ path: "/anlaesse/chilbi", component: Chilbi },
	{ path: "/anlaesse/clean-up-day", component: CleanUpDay },
	{ path: "/anlaesse/floss-rallye", component: FlossRallye },
	{ path: "/anlaesse/piheku", component: PiHeKu },
	{ path: "/anlaesse/snowday", component: Snowday },
	{ path: "/anlaesse/sola", component: SoLa },
	{ path: "/anlaesse/wawei", component: WaWei },
	{ path: "/anlaesse/zoback", component: ZoBack },
	getZobackOrderRouteIfAvailable(),

	// Stufen
	// Redirected groups
	...Array.from(getGroupRedirects()),
	{ path: "/stufen", component: Stufen },
	{
		path: "/stufen/:id",
		component: GroupPage,
		children: [
			{ path: "", redirect: "feed" },
			{ path: "feed", component: GroupFeed },
			{ path: "edit-news-feed", component: EditNewsFeed },
			{ path: "edit-bio", component: EditGroupBio },
			{ path: "send-news", component: SendNewsFeed },
		],
	},

	// Material
	{
		path: "/material",
		component: Material,
		children: [
			{ path: "/", component: Mat },
			{ path: "fallbalken", component: Fallbalken },
			{ path: "pizzamobil", component: Pizzamobil },
			{ path: "bestellung", component: MatOrder },
			{ path: "hoernlihaus", component: HoernliHaus },
			getFallbalkenOrderRouteIfAvailable(),
			getPizzamobilOrderRouteIfAvailable(),
		],
	},

	// Laedeli
	{ path: "/laedeli", component: Laedeli },

	// Links
	{ path: "/links", component: Links },

	// Kontakt
	{ path: "/kontakt", component: Kontakt },

	// Verband
	{ path: "/verband", component: Verband },

	// Fotogalerie
	{
		path: "/fotogalerie",
		beforeEnter() {
			window.open("http://fotos.ceviwie.ch/", "_blank");
		},
	},

	// Redirects
	{ path: "/home", redirect: "/" },

	// Helper tools
	{ path: "/helfer/anfrage", name: "helper-request", component: RequestHelpers },
	{ path: "/helfer/uebersicht", name: "helper-overview", component: HelpersOverview },
	{
		path: "/helfer/admin",
		name: "helper-admin",
		component: HelpersAdminView,
		beforeEnter: requireHelperUser,
	},

	// System items
	{ path: "*", component: NotFound },
];

function requireHelperUser(to, from, next) {
	store.commit("setAppLoadingStatus", true);
	setTimeout(() => {
		const deny = (reason = "", dest = "home") => {
			console.log("Access denied");
			if (reason && reason.length > 0) console.log(":", reason);
			store.commit("setAppLoadingStatus", false);
			next({ name: dest });
		};
		if (!store.state.user) return deny("Not logged in");
		if (!["global_admin", "helper_user"].includes(store.state.user.role))
			return deny("Insufficient permissions");
		store.commit("setAppLoadingStatus", false);
		next();
	}, 1000);
}

function* getGroupRedirects() {
	for (let redirect of store.getters.features.redirect_groups) {
		yield {
			path: `/stufen/${redirect.from}/feed`,
			redirect: `/stufen/${redirect.to}/feed`,
		};
		yield {
			path: `/stufen/${redirect.from}/edit-news-feed`,
			redirect: `/stufen/${redirect.to}/edit-news-feed`,
		};
		yield {
			path: `/stufen/${redirect.from}/edit-bio`,
			redirect: `/stufen/${redirect.to}/edit-bio`,
		};
		yield {
			path: `/stufen/${redirect.from}/send-news`,
			redirect: `/stufen/${redirect.to}/send-news`,
		};
	}
}

function getZobackOrderRouteIfAvailable() {
	if (
		store.getters.features.zoback.online_order_available &&
		Date.now() >= new Date(store.getters.features.zoback.online_order_dates[0])
	) {
		return { path: "/anlaesse/zoback/bestellen", component: ZoBackOrder };
	} else {
		return { path: "/anlaesse/zoback/bestellen", redirect: "/anlaesse/zoback" };
	}
}

function getFallbalkenOrderRouteIfAvailable() {
	if (store.getters.features.fallbalken.legacy_order_form) {
		return {
			path: "/material/fallbalken/reservation",
			redirect: "/material/fallbalken",
		};
	} else {
		return {
			path: "/material/fallbalken/reservation",
			component: FallbalkenReservation,
		};
	}
}

function getPizzamobilOrderRouteIfAvailable() {
	if (store.getters.features.pizzamobil.legacy_order_form) {
		return {
			path: "/material/pizzamobil/reservation",
			redirect: "/material/pizzamobil",
		};
	} else {
		return {
			path: "/material/pizzamobil/reservation",
			component: PizzamobilReservation,
		};
	}
}
