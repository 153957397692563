<template>
	<div id="app">
		<advent2020 v-if="$store.getters.features.advent_calendar.enabled" />
		<message-box />
		<toast-notification />
		<transition
			enter-active-class="animate__animated animate__fadeIn"
			leave-active-class="animate__animated animate__fadeOut"
		>
			<loading-overlay v-if="appLoading === true"></loading-overlay>
		</transition>
		<app-navbar
			:navbar-items="formatNavitems(navbaritems)"
			@mobile-menu-enabled="disablePageScrolling"
		/>
		<header-image />
		<page></page>
		<pwa-update></pwa-update>
		<app-footer />
	</div>
</template>

<script>
import url from "url";
//Scripts / JS
import navitemsMedium from "@/resources/nav/navitems-medium";
import navitemsNarrow from "@/resources/nav/navitems-narrow";
import navitemsTiny from "@/resources/nav/navitems-tiny";
import navitemsWide from "@/resources/nav/navitems-wide";

//Event bus
import { bus } from "./bus";

//Views
import Advent2020 from "./components/Advent2020.vue";
import PwaUpdate from "./components/PwaUpdate.vue";
import AppFooter from "./views/Footer.vue";
import HeaderImage from "./views/HeaderImage.vue";
import LoadingOverlay from "./views/LoadingOverlay.vue";
import MessageBox from "./views/MessageBox.vue";
import AppNavbar from "./views/Navbar/Nav.vue";
import Page from "./views/Page.vue";
import ToastNotification from "./views/ToastNotification.vue";

export default {
	name: "App",
	components: {
		AppNavbar,
		AppFooter,
		Page,
		MessageBox,
		HeaderImage,
		LoadingOverlay,
		ToastNotification,
		Advent2020,
		PwaUpdate,
	},
	data() {
		return {
			navbaritems: [],
		};
	},
	computed: {
		/**
		 * @return {boolean}
		 */
		appLoading() {
			return this.$store.state.appLoadingStatus;
		},
	},
	created() {
		bus.$on("loadedUser", (_loggedIn, _user) => {
			this.$store.commit("setUserLoaded", true);
		});
		this.checkUserSession();
		bus.$emit("appCreated");

		const setupNavbarScale = (name, items, min = null, max = null) => {
			const queryText = [min ? `(min-width: ${min}pt)` : null, max ? `(max-width: ${max}pt)` : null]
				.filter((x) => x)
				.join(" and ");
			const mediaQuery = window.matchMedia(queryText);
			const handler = this.setNavItems(items, name);
			mediaQuery.addEventListener("change", handler);
			handler(mediaQuery);
		};

		setupNavbarScale("mobile", navitemsWide, null, 720);
		setupNavbarScale("tiny", navitemsTiny, 720, 850);
		setupNavbarScale("narrow", navitemsNarrow, 850, 1000);
		setupNavbarScale("medium", navitemsMedium, 1000, 1150);
		setupNavbarScale("wide", navitemsWide, 1150, null);
	},
	mounted() {
		bus.$emit("appMounted");
	},
	methods: {
		setNavItems(items, _name = "unnamed") {
			return (e) => {
				if (e.matches) {
					this.navbaritems = items;
				}
			};
		},
		transformUmlauts(text) {
			var search = ["ae", "oe", "ue", "Ae", "Oe", "Ue"];
			var replace = ["ä", "ö", "ü", "Ä", "Ö", "Ü"];

			for (var i = 0; i < search.length; i++) {
				text = text.replace(search[i], replace[i]);
			}

			return text;
		},
		formatNavitems(items) {
			var transformUmlauts = (text) => {
				var search = ["ae", "oe", "ue", "Ae", "Oe", "Ue"];
				var replace = ["ä", "ö", "ü", "Ä", "Ö", "Ü"];

				for (var i = 0; i < search.length; i++) {
					text = text.replace(search[i], replace[i]);
				}

				return text;
			};
			for (let item of items) {
				item.title = transformUmlauts(item.title);

				if (item.submenus) {
					for (let subitem of item.submenus) {
						subitem.title = transformUmlauts(subitem.title);
					}
				}
			}
			return items;
		},
		checkUserSession() {
			var username = this.$cookie.get("session_user");
			var loginHash = this.$cookie.get("login_hash");
			if (loginHash && username) {
				let userEndpoint = url.resolve(this.$store.getters.usersUrl, username + "?key=username");
				this.$http.get(userEndpoint).then(
					(response) => {
						var user = response.body;
						if (user && user.login_hash == loginHash) {
							this.$store.commit("setSession", user);
							bus.$emit("loadedUser", true, user);
						} else {
							console.log("Cached user incorect password");
							bus.$emit("loadedUser", false, null);
						}
					},
					(error) => {
						bus.$emit("alert", {
							message: `Couldn't fetch user: ${username}\n${error}`,
							type: "error",
						});
						console.log("Cached user fetch error");
						bus.$emit("loadedUser", false, null);
					}
				);
			} else {
				bus.$emit("loadedUser", false, null);
			}
		},
		disablePageScrolling(state) {
			document.documentElement.style.overflow = state ? "hidden" : "scroll";
		},
	},
};
</script>

<style lang="scss">
@import "src/styles/global";
@import "src/styles/vueditor";
</style>
