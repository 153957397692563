<template>
	<div>
		<transition id="pinned" tag="section" name="fade" mode="in-out">
			<div v-if="pinned && pinned.length > 0">
				<article-list :articles="pinned"></article-list>
			</div>
		</transition>
		<transition id="news-feed" tag="section" name="fade" mode="out-in">
			<div v-if="articles && articles.length > 0">
				<article-list :articles="articles"></article-list>
			</div>
			<div v-else-if="loading">
				<vcl-list class="loading-skeleton"></vcl-list>
			</div>
			<div v-else>
				<p>Es gibt momentan keine News.</p>
			</div>
		</transition>
	</div>
</template>

<script>
import { newsArticles } from "@/cms";
import newsParser from "@/mixins/newsParser";
import { VclList } from "vue-content-loading";
import ArticleList from "./ArticleList";

export default {
	components: {
		ArticleList,
		VclList,
	},
	mixins: [newsParser],
	data() {
		return {
			articles: null,
			pinned: null,
			loading: false,
		};
	},
	created() {
		this.fetchArticles();
	},
	methods: {
		async fetchArticles() {
			//Load news articles
			this.loading = true;
			try {
				let articles = await newsArticles.getAll({
					fields: "title,body,author,date,pinned,order,title_hidden",
					sort: "-date",
				});
				const transformedArticles = await Promise.all(
					articles.map(async (x) => {
						x = { ...x, transformedBody: await this.transformInput(x.body) };
						return x;
					})
				);
				this.articles = transformedArticles.filter((x) => x.pinned == 0);
				this.pinned = transformedArticles
					.filter((x) => x.pinned == 1)
					.sort((a, b) => {
						if (a.order < b.order) return -1;
						else if (a.order > b.order) return 1;
						else return 0;
					});
			} catch (exception) {
				console.error(exception);
				this.$store.dispatch("alert", { type: "error", message: exception.error.message });
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import "src/styles/vars.scss";
@import "src/styles/newsArticles.scss";

span.caption {
	color: #aaa;
	display: block;
	margin-bottom: 10px;
	font-family: $app-serif-font;
	text-align: right;
}

@media screen and (max-width: $m-width) {
	.borderless {
		width: calc(100% + #{$m-page-padding * 2}) !important;
		margin-right: -$m-page-padding;
		margin-left: -$m-page-padding;
	}
}

.loading-skeleton {
	width: 100%;
}

@media screen and (min-width: $m-width) {
	.loading-skeleton {
		width: 70%;
	}
}

.fade-enter-active,
.fade-leave-active {
	transition-duration: 0.2s;
	transition-property: opacity;
	transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
	opacity: 0;
}
</style>
