<template>
	<div id="container">
		<h1>Stufen</h1>
		<div v-if="groups" id="group-list">
			<div
				v-for="(group, index) of groups"
				:key="index"
				class="group-widget mobile-borderless"
				:class="group.id == -1 ? 'empty' : ''"
			>
				<img
					v-if="group.id != -1"
					:src="group.img_url"
					:alt="group.name"
					onerror="this.style.display = 'none'"
				/>
				<router-link
					v-if="group.id != -1"
					:to="'stufen/' + group.id + '/feed'"
					class="nostyle group-overlay"
				>
					<span class="group-name">{{ group.name }}</span>
					<span class="group-year">{{ group.year_description }}</span>
				</router-link>
			</div>
		</div>
		<div v-else>
			<loading-spinner info-text="Bitte warten..." theme="mint" />
		</div>
	</div>
</template>
<script>
import LoadingSpinner from "../../components/LoadingSpinner";

export default {
	title: ({ shortAppend }) => shortAppend("Stufen"),
	components: {
		LoadingSpinner,
	},
	data() {
		return {
			groups: null,
		};
	},
	created() {
		let groupsEndpoint = this.$store.getters.groupsUrl;
		this.$http
			.get(groupsEndpoint, {
				params: {
					order: ["year_DESC", "gender_ASC"],
				},
			})
			.then((response) => {
				this.groups = this.processGroups(
					this.fillEmptyGroups(response.body),
					this.$store.getters.features
				);
			})
			.catch((err) => {
				console.log(err);
			});
	},

	methods: {
		processGroups(groups, config) {
			// console.log(groups);

			for (let redirect of config.redirect_groups) {
				let sourceGroup = groups.find((x) => x.id == redirect.from);
				if (sourceGroup != null) {
					sourceGroup.id = redirect.to;

					let targetGroup = groups.find((x) => x.id == redirect.to);
					if (targetGroup != null) {
						sourceGroup.name = targetGroup.name;
						sourceGroup.img_url = targetGroup.img_url;
					}
				}
			}

			return groups;
		},
		/**
		 * @param {Object[]} groups
		 */
		fillEmptyGroups(groups) {
			// Fill in empty group of other sex whenever there is no year equivalent
			const emptyGroup = { id: -1 };
			const newGroups = [];
			for (const group of groups) {
				if (group.gender === "f") {
					// Add empty after
					newGroups.push(group);
					if (!groups.find((x) => x.gender === "m" && x.year === group.year)) {
						newGroups.push(emptyGroup);
					}
				} else if (group.gender === "m") {
					// Add empty before
					if (!groups.find((x) => x.gender === "f" && x.year === group.year)) {
						newGroups.push(emptyGroup);
					}
					newGroups.push(group);
				} else {
					// If group is neutral, put it on its own line
					newGroups.push(group);
				}
			}
			return newGroups;
		},
		getSchoolYear(birthYear) {
			let now = new Date();
			// Take the difference from now and the year the kids were born, and divide it by the number
			// of milliseconds in a year
			let age = (now - new Date(`08/01/${parseInt(birthYear)}`)) / (365 * 24 * 3600 * 1000);

			let primarySchoolStartAge = 7;
			let secondarySchoolStartAge = 13;

			if (age <= primarySchoolStartAge + 1) {
				return "Kindergarten / 1. Klasse";
			} else if (age <= secondarySchoolStartAge) {
				let grade = Math.ceil(age - primarySchoolStartAge);
				return `${grade}. Klasse`;
			} else {
				let grade = Math.ceil(age - secondarySchoolStartAge);
				return `${grade}. Sek`;
			}
		},
	},
};
</script>
<style lang="scss" scoped>
@import "../../styles/vars";

div#group-list {
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 5px;

	div.group-widget {
		position: relative;
		height: 120px;
		display: flex;
		justify-content: center;
		align-items: center;
		background: linear-gradient(
				217deg,
				lighten(desaturate(rgba(255, 30, 50, 0.8), 50), 30),
				rgba(255, 0, 0, 0) 70.71%
			),
			linear-gradient(
				127deg,
				lighten(desaturate(rgba(0, 255, 120, 0.8), 50), 30),
				rgba(0, 255, 0, 0) 70.71%
			),
			linear-gradient(336deg, lighten(rgba(60, 40, 255, 0.8), 15), rgba(0, 0, 255, 0) 70.71%);

		img {
			object-fit: cover;
			height: 100%;
			width: 100%;
		}

		.group-overlay {
			position: absolute;
			top: 0;
			bottom: 0;
			width: 100%;
			color: white;
			display: flex;
			flex-direction: column;
			justify-content: center;

			&:hover {
				background-color: #fff6;
			}

			span {
				display: block;
				text-align: center;
				padding: 5px;
				text-shadow: 0 0 15px #000c;

				&.group-name {
					font-size: 36px;
					font-weight: bold;
				}
				&.group-year {
					font-size: 16px;
				}
			}
		}

		&:hover {
			cursor: pointer;
		}
	}

	div.empty {
		background: rgba(0, 0, 0, 0.05);
	}
}

@media (min-width: $m-width) {
	div#group-list {
		grid-template-columns: 1fr 1fr;
		grid-gap: 10px;

		div.group-widget {
			height: 150px;
			&:first-of-type {
				grid-column: 1 / -1;
			}
		}
	}
}
</style>
