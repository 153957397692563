<template>
	<div>
		<directus-page page-id="cevi-turnen"></directus-page>
	</div>
</template>
<script>
import DirectusPage from "@/components/DirectusPage";

export default {
	components: { DirectusPage },
};
</script>
<style lang="scss" scoped>
@import "src/styles/vars";

.calendar {
	width: 100%;
	height: 400px;
}

@media (min-width: $m-width) {
	.calendar {
		width: 50%;
		height: 450px;
	}
}
</style>
