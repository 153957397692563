<template>
	<div id="footer">
		<p class="sans">&copy; 2020 Cevi Wiesendangen-Elsau-Hegi</p>
		<p class="sans">
			Website:
			<span class="author">Federico Mantovani v/o Hankoon</span>
		</p>
		<div id="links">
			<a class="styled" href="https://ceviwie.webling.ch/" target="_blank">Webling</a>
			<router-link class="styled" to="/feedback">Feedback abgeben</router-link>
			<a class="styled" href="mailto:info@ceviwie.ch">Kontakt</a>
		</div>
		<div id="logo"></div>
		<div id="technical-info">
			<span id="version">Version: {{ version || "n/a" }}</span>
			<span id="mode">Mode: {{ mode || "n/a" }} (NODE_ENV: {{ nodeEnv }})</span>
		</div>
	</div>
</template>

<script>
import pjson from "../../package.json";

export default {
	data() {
		return {
			version: pjson.version,
			mode: process.env.VUE_APP_CONFIG_NAME,
			nodeEnv: process.env.NODE_ENV,
		};
	},
};
</script>

<style scoped lang="scss">
@import "../styles/vars";

#technical-info {
	text-align: right;

	span {
		margin: 5px;
	}

	#version,
	#mode {
		opacity: 0.2;
		font-size: 12px;
	}
}

#footer {
	background-color: $dark;
	color: $white;
	padding: $m-page-padding;
	min-height: 200px;
	text-align: center;

	div#links {
		margin: 50px 0;
	}

	p {
		margin: 20px;
		color: $light;
	}

	a {
		display: block;
		margin: 20px auto;
		width: max-content;
	}

	.author {
		color: rgba($light, 0.7);
	}

	div#logo {
		background: url("../assets/svg/logo-cevi-notext-white.svg") no-repeat center center;
		margin: 10px 0 40px 0;
		filter: opacity(0.15);
		height: 100px;
		width: 100%;
		position: relative;
		z-index: auto;
	}
}

@media screen and (min-width: $m-width) {
	#footer {
		a {
			display: inline;
			margin: 20px;
		}
	}
}

#technical-info {
	text-align: center;
}
</style>
